import html2pdf from 'html2pdf.js';
import Logo from '../img/logo.js';

export const exportPDF = (elementId, filename, showSignatures, cremationType) => {
    // Sélectionnez l'élément que vous souhaitez convertir en PDF
    const originalElement = document.getElementById(elementId);
    const element = originalElement.cloneNode(true);
    const backgroundColor = cremationType === 'Crémation collective' ? '#F1C40F' :
                            cremationType === 'Crémation individuelle non accompagnée' ? '#7B241C' :
                            cremationType === 'Crémation individuelle accompagnée' ? '#1b5e20' : 'white';
    // Créez l'en-tête
    const header = document.createElement('div');
    // header.style.backgroundColor = '#1976d2';
    // header.style.padding = '10px';
    // header.style.display = 'flex';
    // header.style.alignItems = 'center';
    header.innerHTML = `
     <div style="background-color: ${backgroundColor}; padding-bottom: 2px; padding-top: 8px; padding-left: 8px; display: flex; align-items: center; margin-bottom: 0px" id="header-section">
        <img src="${Logo}" style="height: 70px; margin-right: 10px; margin-top: 10px; margin-left: 10px">
        <span style="color: white; font-size: 20px; align-items: center;">Crématorium Animalier de Picardie</span>
    </div>
 `;

    // Insérez l'en-tête au début de l'élément
    element.insertAdjacentElement('afterbegin', header);

    // Vérifiez si la section "Informations" existe
    const infoSection = element.querySelector('#cremation-section');
    const proprietaireSection = element.querySelector('#proprietaire-section');
    // if (infoSection && proprietaireSection) {
    //     // Ajoutez un saut de page avant la section "Informations"
    //     const pageBreak = document.createElement('div');
    //     pageBreak.style.cssText = 'page-break-before: always; height: 0; visibility: hidden;';
    //     infoSection.insertAdjacentElement('beforebegin', pageBreak);
    // }

    // Options pour html2pdf
    const options = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: filename,
        image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2, windowWidth: 1024 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    element.style.overflow = 'hidden'; // Empêche le débordement
    // element.style.width = '100%'; // Assurez-vous que la largeur est correcte
    element.style.boxSizing = 'border-box';

    // Ajoutez un saut de page avant les signatures
    // if (proprietaireSection) {
    //     const signaturePageBreak = document.createElement('div');
    //     signaturePageBreak.style.cssText = 'page-break-before: always; height: 0; visibility: hidden;';
    //     element.insertAdjacentElement('beforeend', signaturePageBreak);
    // }
    const htmlContent = `
    <div style="margin-left: 20px;" id="signature-section">
        <div style="display: flex; justify-content: space-between; margin-bottom: 1px;">
            <div style="border: 1px solid #ccc; border-radius: 8px; padding: 5px 10px 10px 10px; margin-right: 10px; flex: 1;">
                <h6>Signature du vétérinaire</h6>
                <div style="height: 100px; border: 1px dashed #ccc; margin-top: 5px;"></div>
            </div>
            <div style="border: 1px solid #ccc; border-radius: 8px; padding: 5px 10px 10px 10px; margin-left: 10px; flex: 1;">
                <h6>Signature du propriétaire</h6>
                <div style="height: 100px; border: 1px dashed #ccc; margin-top: 5px;"></div>
            </div>
        </div>
        <h7 style="margin-top: 20px; font-weight: bold; font-size: 12px;">Consentement à la collecte et à l'utilisation des données personnelles :</h7>
        <p style="margin-top: 2px; font-style: italic; font-size: 10px;">
            En signant ce document, j'autorise la clinique vétérinaire à transmettre mes coordonnées personnelles (nom, prénom, adresse, numéro de téléphone) au Crématorium Animalier de Picardie dans le cadre de la demande de crémation individuelle de mon animal.
            <br />
            Je comprends que ces informations seront strictement utilisées pour la gestion et l'organisation de cette crémation, et qu'elles ne seront en aucun cas revendues, partagées ou utilisées à des fins commerciales, publicitaires ou pour tout autre usage que la crémation de mon animal.
            <br />
            Conformément au Règlement Général sur la Protection des Données (RGPD), je dispose d'un droit d'accès, de rectification et de suppression de mes données personnelles. Pour exercer ces droits, je peux contacter le crématorium à l'adresse suivante : accueil@crematorium-animalierdepicardie.fr
        </p>
    </div>
    `;
    if (showSignatures) {
        element.insertAdjacentHTML('beforeend', htmlContent);
    }
    // element.appendChild(rgpdMessage);
    // element.style.width = '4096px';
    // Génération du PDF
    html2pdf().from(element).set(options).save();
    return true;

};

// // Ajout du cadre pour la signature du vétérinaire et du propriétaire
// // Ajout d'un saut de page avant le contenu de la signature et du message RGPD
// // const pageBreak = document.createElement('div');
// // pageBreak.style.pageBreakBefore = 'always';
// // formattedContent.appendChild(pageBreak);

// const signatureContainer = document.createElement('div');
// signatureContainer.style.display = 'flex';
// signatureContainer.style.justifyContent = 'space-between';
// signatureContainer.style.marginTop = '20px';

// const vetSignatureBox = document.createElement('div');
// vetSignatureBox.style.border = '1px solid #ccc';
// vetSignatureBox.style.borderRadius = '8px';
// vetSignatureBox.style.padding = '20px';
// vetSignatureBox.style.marginRight = '10px';
// vetSignatureBox.style.flex = '1';

// const vetSignatureTitle = document.createElement('h6');
// vetSignatureTitle.innerText = 'Signature du vétérinaire';
// vetSignatureBox.appendChild(vetSignatureTitle);

// const vetSignatureField = document.createElement('div');
// vetSignatureField.style.height = '100px';
// vetSignatureField.style.border = '1px dashed #ccc';
// vetSignatureField.style.marginTop = '10px';
// vetSignatureBox.appendChild(vetSignatureField);

// const ownerSignatureBox = document.createElement('div');
// ownerSignatureBox.style.border = '1px solid #ccc';
// ownerSignatureBox.style.borderRadius = '8px';
// ownerSignatureBox.style.padding = '20px';
// ownerSignatureBox.style.marginLeft = '10px';
// ownerSignatureBox.style.flex = '1';

// const ownerSignatureTitle = document.createElement('h6');
// ownerSignatureTitle.innerText = 'Signature du propriétaire';
// ownerSignatureBox.appendChild(ownerSignatureTitle);

// const ownerSignatureField = document.createElement('div');
// ownerSignatureField.style.height = '100px';
// ownerSignatureField.style.border = '1px dashed #ccc';
// ownerSignatureField.style.marginTop = '10px';
// ownerSignatureBox.appendChild(ownerSignatureField);

// signatureContainer.appendChild(vetSignatureBox);
// signatureContainer.appendChild(ownerSignatureBox);
// formattedContent.appendChild(signatureContainer);

// // Ajout du message RGPD
// const rgpdTitle = document.createElement('h7');
// rgpdTitle.innerText = "Consentement à la collecte et à l'utilisation des données personnelles :";
// rgpdTitle.style.marginTop = '20px';
// rgpdTitle.style.fontWeight = 'bold';
// formattedContent.appendChild(rgpdTitle);

// const rgpdMessage = document.createElement('p');
// rgpdMessage.innerHTML = `En signant ce document, je soussigné(e) ${demande.ownerFirstName} ${demande.ownerLastName}, autorise la clinique vétérinaire à transmettre mes coordonnées personnelles (nom, prénom, adresse, numéro de téléphone) au Crématorium Animalier de Picardie dans le cadre de la demande de crémation individuelle de mon animal.
// <br />
// Je comprends que ces informations seront strictement utilisées pour la gestion et l'organisation de cette crémation, et qu'elles ne seront en aucun cas revendues, partagées ou utilisées à des fins commerciales, publicitaires ou pour tout autre usage que la crémation de mon animal.
// <br />
// Conformément au Règlement Général sur la Protection des Données (RGPD), je dispose d'un droit d'accès, de rectification et de suppression de mes données personnelles. Pour exercer ces droits, je peux contacter le crématorium à l'adresse suivante : accueil@crematorium-animalierdepicardie.fr`;
// rgpdMessage.style.marginTop = '20px';
// rgpdMessage.style.fontStyle = 'italic';
// formattedContent.appendChild(rgpdMessage);