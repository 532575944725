import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, TextField, Grid, Box, Container, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { fetchCliniques } from './models/getCliniques';
import { CremaCardExport } from './views/cremaCard';
import { fetchDemandes } from './models/getDemandes';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { exportPDF } from './controllers/exportPDF';

const ExportDemandes = ({ open, onClose }) => {
    const [cliniques, setCliniques] = useState([]);
    const [clinic, setClinic] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [demandes, setDemandes] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchCliniques((cliniquesData) => {
            setCliniques(cliniquesData.sort((a, b) => b.clinique.localeCompare(a.clinique)));
        });

        // Cleanup function
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        handleFetchDemandes();
    }, [clinic, startDate, endDate]);

    const handleClinicChange = (event) => {
        setClinic(event.target.value);
    };

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate.toISOString());
        // await handleFetchDemandes();
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate.toISOString());
        // await handleFetchDemandes();
    };

    const handlePdf = () => {
        const elementId = 'pdf-content';
        const filename = `Cremations_${cliniques.find(c => c.userUID === clinic).clinique}_${new Date(startDate).toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}_${new Date(endDate).toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}.pdf`;

        exportPDF(elementId, filename, false);
    };

    const handleFetchDemandes = async () => {
        if (clinic && startDate && endDate) {
            const start = dayjs(startDate).startOf('day').toDate();
            const end = dayjs(endDate).endOf('day').toDate();


            const filteredDemandes = await fetchDemandes("admin", (demandesData) => {

                const filtered = demandesData.filter(demande => {
                    const demandeDate = demande.cremaDate ? dayjs(demande.cremaDate).toDate() : null;
                    if (demandeDate) {
                        const isInDateRange = demandeDate >= start && demandeDate <= end;
                        const isCorrectClinic = demande.userID === clinic || demande.UID === clinic;
                        return isInDateRange && isCorrectClinic;
                    }
                    return false;
                });

                setDemandes(filtered);
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Exporter les Demandes</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} color="inherit">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1 }, '& .MuiButton-root': { m: 1 }, paddingLeft: 1, paddingRight: 1 }}>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel id="clinic-label">Choisir la clinique</InputLabel>
                                    <Select
                                        labelId="clinic-label"
                                        id="clinic"
                                        value={clinic}
                                        onChange={handleClinicChange}
                                        label="Choisir la clinique"
                                    >
                                        {cliniques.map((clinique) => (
                                            <MenuItem key={clinique.userUID} value={clinique.userUID}>
                                                {clinique.clinique}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date de début"
                                            InputLabelProps={{ shrink: true }}
                                            name="startDate"
                                            // value={demande.ramassageDate ? dayjs(new Date(demande.ramassageDate)) : null}
                                            onChange={handleStartDateChange}
                                            // disabled={!isEditMode || userRole !== 'admin'}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date de fin"
                                            InputLabelProps={{ shrink: true }}
                                            name="endDate"
                                            onChange={handleEndDateChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {demandes.length > 0 && (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                <IconButton onClick={handlePdf}>
                                    <PictureAsPdfIcon />
                                </IconButton>
                            </Box>
                        )}
                        <Container id="pdf-content" sx={{ width: "100%" }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }} hidden={demandes.length === 0}>
                                Export des crémations effectuées pour la clinique : <strong>{cliniques.find(c => c.userUID === clinic)?.clinique}</strong> entre {new Date(startDate).toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} et {new Date(endDate).toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
                            </Typography>
                            {demandes && demandes.map((demande, index) => (
                                <React.Fragment key={demande.id}>
                                    <CremaCardExport demande={demande} />
                                    {(index + 1) % 6 === 0 && index < demandes.length - 1 && (
                                        <div style={{ pageBreakAfter: 'always', height: 0, visibility: 'hidden' }}></div>
                                    )}
                                </React.Fragment>
                            ))}
                        </Container>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export default ExportDemandes;
