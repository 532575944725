import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export const fetchUserData = async (uid, clinique) => {
    try {
        if (uid && clinique) {
            const userRef = query(collection(db, "veto"), where("UID", "==", uid), where("clinique", "==", clinique));
            const querySnapshot = await getDocs(userRef);
            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        }
        else return null
    } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
        return null;
    }
};