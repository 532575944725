import { Card, CardContent, Box, Typography, Grid } from '@mui/material';
import Medication from '@mui/icons-material/Medication';
import Person from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';

export const CremaCardExport = ({ demande, showBarcode }) => (
    <Card variant="outlined" sx={{ marginBottom: 2, backgroundColor: 'lightblue', width: '100%', marginLeft: 1, marginRight: 1 }}>
        <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
                <Typography variant="h6" align="center" fontWeight={600} fontSize={"100%"} sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '70%'
                }}>
                    {demande.animalName + ' - ' + demande.animalType}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                {demande.ownerFirstName && demande.ownerLastName && (
                    <>
                        <Box sx={{ marginRight: 1, color: "black" }}><Person /></Box>
                        <Typography variant="body1" align="center" color="#222831">
                            {demande.ownerFirstName} {demande.ownerLastName}
                        </Typography>
                    </>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                <Box sx={{ marginRight: 1, color: "black" }}><Medication /></Box>
                <Typography variant="body1" align="center" color="textSecondary">
                    {demande.cremationType} {demande.cremaDate && "effectuée le " + new Date(demande.cremaDate).toLocaleDateString()}
                </Typography>
            </Box>
            {showBarcode && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div dangerouslySetInnerHTML={{ __html: demande.barcodeSvg }} />
                </Grid>
            )}
        </CardContent>
    </Card>
);

export const CremaCard = ({ demande, handleEdit }) => {
    const getBackgroundColorStatut = (statut) => {
        switch (statut) {
            case 'Nouvelle demande':
                return '#A93226';
            case 'Ramassage programmé':
                return '#A93226';
            case 'Animal arrivé au crématorium':
                return '#229954';
            case 'Crémation effectuée':
                return '#D68910';
            default:
                return '#222831';
        }
    };

    const getBackgroundColorType = (animalType) => {
        switch (animalType) {
            case 'Chien':
                return '#1A5276';
            case 'Chat':
                return '#0E6655';
            case 'NAC':
                return '#7B241C';
            default:
                return '#222831';
        }
    };

    const getIcon = (animalType) => {
        switch (animalType) {
            case 'Chien':
                return <img src="Dog.png" alt="Dog" style={{ width: '35px', height: '35px' }} />
            case 'Chat':
                return <img src="Cat.png" alt="Dog" style={{ width: '35px', height: '35px' }} />;
            case 'NAC':
                return <img src="lapin.png" alt="Dog" style={{ width: '35px', height: '35px' }} />;
            default:
                return null;
        }
    };

    const backgroundColorStatut = getBackgroundColorStatut(demande.statut);
    const backgroundColorType = getBackgroundColorType(demande.animalType);
    const icon = getIcon(demande.animalType);

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={demande.id}>
            <Card variant="outlined" sx={{ height: '100%', padding: 0, borderRadius: 5, display: "flex", flexDirection: "column", justifyContent: 'space-between' }} onClick={() => handleEdit(demande)} style={{ cursor: 'pointer' }}>
                <CardContent style={{ padding: 0, flexGrow: 1 }}>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1, backgroundColor: backgroundColorType, color: "whitesmoke", padding: "0.5em", textAlign: "center" }}>
                    
                        {icon && <Box sx={{ position: 'absolute', left: 0, marginLeft: 1 }}>{icon}</Box>}
                        <Typography variant="h6" align="center" gutterBottom paddingTop={1} fontWeight={600} fontSize={"100%"} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '70%'  // Ajustez cette valeur en fonction de la largeur de votre card
                        }}>
                            {demande.animalName + ' - ' + demande.animalType}
                        </Typography>
                        {/* <IconButton onClick={e => handleRowClick(demande)} sx={{ color: 'white' }}>
    <EditIcon />
</IconButton> */}
                    </Box>

                    {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1, color: "whitesmoke", paddingTop: "0.5em", textAlign: "center" }}> */}
                        {demande.ownerFirstName ? (
                            <>
                                <Typography variant="body1" align="center" color="#222831" sx={{ paddingBottom: 2, paddingTop: 1, marginBottom: 2, borderBottom: '1px solid', borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                                <Person sx={{ verticalAlign: 'middle' }} /> {demande.ownerFirstName} {demande.ownerLastName}
                                </Typography>
                            </>
                        ) : (
                            <Box sx={{ height: '24px' }}></Box>
                        )}
                    {/* </Box> */}

                    {/* {demande.clinique != null && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1, color: "whitesmoke", paddingBottom: "0.5em", textAlign: "center" }}>
                            <Box sx={{ marginRight: 1, color: "black", alignItems: "center" }}><Medication /></Box>
                            <Typography variant="body1" align="center" color="textSecondary">
                                {demande.clinique}
                            </Typography>
                        </Box>
                    )} */}
                    <Typography variant="body2" align="center" color="textSecondary" sx={{ marginBottom: 1,  }}> 
                        {demande.cremationType} {demande.cremaDate && "effectuée le " + new Date(demande.cremaDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" align="center" color={backgroundColorStatut} fontWeight={600} >
                        {demande.statut}
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary">
                        {demande.ramassageDate && demande.statut == "Ramassage programmé" ? new Date(demande.ramassageDate).toLocaleDateString() + ' ' + demande.ramassageTime : ""}
                    </Typography>
                    <Box sx={{ borderTop: '1px solid', borderColor: 'rgba(0, 0, 0, 0.12)', padding: 1, marginTop: 2, textAlign: 'center' }}>
                        <Typography variant="body2" color="textSecondary">
                            {`Créée le ${new Date(demande.created).toLocaleDateString()} à ${new Date(demande.created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};
