import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

export default function UrneCard({ urne, onClick, onDelete, userRole }) {
    const { texte, image, titre } = urne;

    return (
        <Card
            sx={{ maxWidth: 600 }}
            onClick={onClick}
            style={{ cursor: 'pointer' }}>
            <CardMedia
                sx={{ height: 300, width: '100%' }}
                image={image}
                title={titre}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {titre}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {texte}
                </Typography>
            </CardContent>
            {userRole === "admin" && (
                <CardActions>
                    <Button size="small" onClick={(event) => { event.stopPropagation(); onDelete(); }}>
                        <DeleteIcon />
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}