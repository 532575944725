import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Box, TextField, Button, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { useAlert } from './AlertContext'; // Remplacez par la bibliothèque d'alertes que vous utilisez
import { auth, db } from './firebase';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { localeText } from './config/localeText';
import CloseIcon from '@mui/icons-material/Close';

export const GetFournitures = (userRole, onUpdateFournitures) => {
    const [fournitures, setFournitures] = useState([]);
    const [viewFourniture, setViewFourniture] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const { showAlert } = useAlert();

    const fetchFournitures = () => {
        setLoading(true);
        const fournituresCollectionRef = collection(db, "fournitures");

        const unsubscribe = onSnapshot(fournituresCollectionRef, (snapshot) => {
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            data.sort((a, b) => a.title.localeCompare(b.title));
            setFournitures(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des fournitures:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchFournitures();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleRowClick = (fourniture) => {
        setViewFourniture(fourniture);
        setOpenAddModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewFourniture(null);
    };
    const handleDeleteFourniture = async (fourniture) => {
        try {
            await deleteDoc(doc(db, "fournitures", fourniture.id)); // Suppression réelle de la base de données
            showAlert("Fourniture supprimée avec succès !", 'success');
        } catch (error) {
            console.error("Erreur lors de la suppression de la fourniture :", error);
            showAlert("Erreur lors de la suppression.", 'error');
        }
    };


    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: (params) => (
                <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteFourniture(params.row); }}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Fournitures</Typography>
            {isMobile ? (
                <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10, overflow: 'auto' }}>
                    {fournitures.map((fourniture) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={fourniture.id}>
                            <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom: 1.5 }} onClick={() => handleRowClick(fourniture)} style={{ cursor: 'pointer' }}>
                                <CardContent style={{ padding: 0 }}>
                                    <Typography variant="h6" align="center" gutterBottom paddingTop={1} fontWeight={600} fontSize={"100%"}>
                                        {fourniture.title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {/* <IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpenAddModal(true)}
                // sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <AddIcon />
                </IconButton> */}
                    <DataGrid
                        rows={fournitures}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowClick={(params) => handleRowClick(params.row)}
                        localeText={localeText}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour chaque en-tête de colonne
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                color: 'white', // Couleur du texte pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                backgroundColor: '#ffffff', // Couleur de fond pour les lignes paires
                            },
                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les lignes impaires
                                color: 'white',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                </>
            )}

            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpenAddModal(true)}
                sx={{
                    position: 'fixed',
                    bottom: 80,
                    right: 30,
                    backgroundColor: "#1976D2"
                }}
            >
                <AddIcon />
            </Fab>
            <AddFournitures fournitureProp={viewFourniture} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateFournitures={onUpdateFournitures} />
        </Container>
    );
};

export const AddFournitures = ({ fournitureProp, userRole, onClose, open, onUpdateFournitures }) => {
    const { showAlert } = useAlert();
    const [fourniture, setFourniture] = useState({
        title: '',
        id: null,
    });

    useEffect(() => {
        if (fournitureProp) {
            setFourniture(fournitureProp);
        } else {
            setFourniture({ title: '', id: null });
        }
    }, [fournitureProp]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFourniture(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            if (fourniture.id) {
                await updateDoc(doc(db, "fournitures", fourniture.id), fourniture);
                showAlert("Fourniture mise à jour avec succès !", 'success');
            } else {
                const { id, ...data } = fourniture;
                await addDoc(collection(db, "fournitures"), data);
                showAlert("Nouvelle fourniture créée avec succès !", 'success');
            }
            onUpdateFournitures();
            onClose();
        } catch (error) {
            console.error("Erreur lors de la soumission de la fourniture :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        }
    };

    return (
        <Dialog open={open} onClose={() => { onClose(); setFourniture({ title: '', id: null }); }} maxWidth="md" fullWidth>
            <DialogTitle>
                {fourniture.id ? "Modifier la fourniture" : "Nouvelle fourniture"}
                <IconButton
                    aria-label="close"
                    onClick={() => { onClose(); setFourniture({ title: '', id: null }); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                            <Box component="form" onSubmit={handleFormSubmit}>
                                <TextField
                                    name="title"
                                    label="Titre"
                                    value={fourniture.title}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <Button type='submit' variant="contained" color="success">
                                    Enregistrer
                                </Button>
                            </Box>
                        </FormControl>
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
