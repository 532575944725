import { db } from "../firebase";
import { auth } from "../firebase";
import { collection, query, where, onSnapshot, Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import { fetchUserData } from "./getUserData";

export const fetchDemandes = (userRole, setDemandes, clinique, userID) => {
    let demandesCollectionRef;
    if (auth.currentUser) {
        if (userRole === 'admin') {
            demandesCollectionRef = collection(db, "demandes");
        } else {
            demandesCollectionRef = query(collection(db, "demandes"), where("UID", "==", auth.currentUser.uid), where("userID", "==", userID));
        }

        const unsubscribe = onSnapshot(demandesCollectionRef, async (snapshot) => {
            const demandesData = snapshot.docs.map(async (doc) => {
                const demandeData = { id: doc.id, ...doc.data() };
                const userData = await fetchUserData(demandeData.UID);
                if (userData) {
                    demandeData.userName = userData.clinique;
                }
                if (demandeData.created instanceof Timestamp) {
                    demandeData.created = dayjs(demandeData.created.toDate()).format('DD/MM/YYYY');
                }
                return demandeData;
            });

            const demandesDataResolved = await Promise.all(demandesData);
            const data = [...demandesDataResolved.filter(demande => demande.deleted !== true)];
            data.sort((a, b) => new Date(b.created) - new Date(a.created));
            setDemandes(data);
            // setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des demandes:", error);
            // setLoading(false);
        });

        // Retourner la fonction de désabonnement pour nettoyer l'écouteur lors du démontage du composant
        return unsubscribe;
    }
};