import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, deleteDoc, onSnapshot, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import { Box, Button, CircularProgress, Container, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Select, MenuItem, TextField, Typography, useMediaQuery, IconButton, Grid, InputLabel, FormControl, Fab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { localeText } from './config/localeText';
import AddIcon from '@mui/icons-material/Add';

const Users = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [clinique, setClinique] = useState('');
  const [adresse, setAdresse] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [ville, setVille] = useState('');
  const [telephone, setTelephone] = useState('');
  const [profile, setProfile] = useState('');
  const [UID, setUID] = useState('');
  const [cliniqueExistante, setCliniqueExistante] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isAddProfile, setIsAddProfile] = useState(false);


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'veto'), (querySnapshot) => {
      const userList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(userList);
      setLoading(false);
    });

    // Nettoyage de l'abonnement lors du démontage du composant
    return () => unsubscribe();
  }, []);

  const handleCreateOrUpdateUser = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const functions = getFunctions();
    const updateUserDetails = httpsCallable(functions, 'updateUserDetails');
    const addUserFunction = httpsCallable(functions, 'addUser');
    try {
      let userRef;
      if (editingUser) {
        if (!editingUser.child) {
          userRef = doc(db, 'veto', editingUser.id);
          await setDoc(userRef, { email, clinique, adresse, codePostal, ville, telephone, profile }, { merge: true });
          await updateUserDetails({
            uid: editingUser.id,
            email,
            password: password ? password : null,
          });
        } else {
          await setDoc(doc(db, 'veto', editingUser.id), { email, clinique, adresse, codePostal, ville, telephone, profile }, { merge: true });
        }
      } else {
        if (!isAddProfile) {
          await addUserFunction({ email, password, clinique, adresse, codePostal, ville, telephone, profile });
        } else {
          await addDoc(collection(db, 'veto'), { email, clinique, adresse, codePostal, ville, telephone, profile, UID: UID, child: true });
        }
      }

      setSuccessMessage('Utilisateur créé/modifié avec succès !');
      resetForm();
      setIsFormVisible(false);
      setLoading(false);
    } catch (error) {
      setError(`Failed to update user: ${error.message}`);
    } finally {
      setLoadingForm(false);
    }
  };

  const handleRowClick = (user) => {
    setLoadingForm(false);
    setEditingUser(user);
    setEmail(user.email);
    setClinique(user.clinique);
    setAdresse(user.adresse);
    setCodePostal(user.codePostal);
    setVille(user.ville);
    setTelephone(user.telephone);
    setIsFormVisible(true);
    setProfile(user.profile);
  };

  const handleDelete = (userId) => {
    setUserToDelete(userId);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteClick = (e, userId) => {
    e.stopPropagation();
    handleDelete(userId);
  };

  const confirmDelete = async () => {
    setIsDeleteLoading(true);
    const deleteAuthUser = httpsCallable(getFunctions(), 'deleteUser');
    try {
      await deleteAuthUser({ uid: userToDelete });
      await deleteDoc(doc(db, 'veto', userToDelete));
      // fetchUsers();
      console.log(`Successfully deleted user ${userToDelete} from Firestore.`);
    } catch (error) {
      console.error(`Failed to delete user: ${error.message}`);
    } finally {
      setIsDeleteDialogOpen(false);
      setUserToDelete(null);
      setIsDeleteLoading(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setClinique('');
    setAdresse('');
    setCodePostal('');
    setVille('');
    setTelephone('');
    setError('');
    setEditingUser(null);
    setIsFormVisible(false);
  };

  return (
    <Container>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Liste des utilisateurs</Typography>
      <Fab
        color="primary"
        aria-label="add"
        onClick={(event) => {
          const menu = document.getElementById('fab-menu');
          menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
        }}
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 30,
          backgroundColor: "#1976D2"
        }}
      >
        <AddIcon />
      </Fab>
      <div id="fab-menu" style={{ display: 'none', position: 'fixed', bottom: 140, right: 30, zIndex: 1000 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsFormVisible(true);
            document.getElementById('fab-menu').style.display = 'none';
          }}
          sx={{ mr: 1 }}
        >
          Nouveau utilisateur
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setIsAddProfile(true);
            setIsFormVisible(true);
            document.getElementById('fab-menu').style.display = 'none';
          }}
        >
          Ajouter une clinique à un utilisateur
        </Button>
      </div>
      {/* <IconButton color="primary" onClick={() => setIsFormVisible(true)}>
        <AddIcon />
      </IconButton> */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        isMobile ? (
          <List sx={{ width: '100%', bgcolor: 'background.paper', paddingBottom: 10 }}>
            {users.map(user => (
              <ListItem key={user.id} onClick={() => handleRowClick(user)}>
                <ListItemText
                  primary={user.clinique}
                  secondary={`Email: ${user.email}, Adresse: ${user.adresse || ''}, ${user.codePostal || ''} ${user.ville || ''}, Téléphone: ${user.telephone || ''}, Profil: ${user.profile || ''}`}
                />
                <IconButton edge="end" onClick={(e) => { handleDelete(user.id); }}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ height: "100%", width: '100%' }}>
            <DataGrid
              rows={users}
              columns={[
                { field: 'clinique', headerName: 'Clinique Vétérinaire', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'adresse', headerName: 'Adresse', flex: 1 },
                { field: 'codePostal', headerName: 'Code Postal', flex: 0.5 },
                { field: 'ville', headerName: 'Ville', flex: 0.5 },
                { field: 'telephone', headerName: 'Téléphone', flex: 0.5 },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  flex: 0.5,
                  renderCell: (params) => (
                    <IconButton onClick={(e) => handleDeleteClick(e, params.row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              ]}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              onRowClick={(params) => handleRowClick(params.row)}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#7cb1e2', // Couleur de fond pour les en-têtes de colonnes
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#7cb1e2', // Couleur de fond pour chaque en-tête de colonne
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: 'white', // Couleur du texte pour les en-têtes de colonnes
                },
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: '#ffffff', // Couleur de fond pour les lignes paires
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: '#7cb1e2', // Couleur de fond pour les lignes impaires
                  color: 'white',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-cell': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-row.Mui-selected': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
              localeText={localeText}
            />
          </Box>
        )
      )}
      {isFormVisible && (
        <Dialog open={isFormVisible} onClose={resetForm} maxWidth="sm" fullWidth>
          <DialogTitle>{editingUser ? 'Modifier un utilisateur' : 'Créer un utilisateur'}</DialogTitle>
          <DialogContent>
            <Box component="form" onSubmit={handleCreateOrUpdateUser} sx={{ '& .MuiTextField-root': { m: 1 }, '& .MuiButton-root': { m: 1 }, paddingLeft: 1, paddingRight: 1 }}>
              <Grid container spacing={2} direction="column">
                {!isAddProfile && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                    {editingUser && !editingUser.child && (
                      <Grid item xs={12}>
                        <TextField
                          label="Mot de passe"
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          helperText="Laisser vide pour ne pas changer le mot de passe"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        label="Clinique Vétérinaire"
                        value={clinique}
                        onChange={e => setClinique(e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {(isAddProfile || editingUser?.child) && (
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel>Mail de la clinique existante</InputLabel>
                        <Select
                          name="clinique"
                          value={isAddProfile ? cliniqueExistante : email}
                          onChange={e => {
                            const selectedUser = users.find(user => user.email === e.target.value);
                            setUID(selectedUser.UID);
                            setEmail(e.target.value);
                            setCliniqueExistante(e.target.value);
                          }}
                          required
                          label="Mail de la clinique existante"
                        >
                          {[...new Set(users.map(user => user.email))]
                            .sort()
                            .map((email, index) => (
                              <MenuItem key={index} value={email} id={users.find(user => user.email === email).UID}>
                                {email}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Clinique Vétérinaire"
                        value={clinique}
                        onChange={e => setClinique(e.target.value)}
                        required
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Adresse"
                    value={adresse}
                    onChange={e => setAdresse(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Code Postal"
                    value={codePostal}
                    onChange={e => setCodePostal(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Ville"
                    value={ville}
                    onChange={e => setVille(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Téléphone"
                    value={telephone}
                    onChange={e => setTelephone(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel>Profil</InputLabel>
                    <Select
                      name="profile"
                      value={profile}
                      onChange={e => setProfile(e.target.value)}
                      required
                      label="Profil"
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="veterinaire">Vétérinaire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" variant="contained" color="primary" disabled={loadingForm}>
                    {loadingForm ? <CircularProgress size={24} /> : (editingUser ? 'Modifier' : 'Créer')}
                  </Button>
                  <Button onClick={resetForm} variant="contained" color="secondary" sx={{ ml: 1 }}>
                    Annuler
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="secondary">Annuler</Button>
          <Button onClick={confirmDelete} color="primary" disabled={isDeleteLoading}>
            {isDeleteLoading ? <CircularProgress size={24} /> : 'Supprimer'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;
