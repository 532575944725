import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, Timestamp, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebase';
import { Pets as PetsIcon, Person, Medication, } from '@mui/icons-material';
import { handleSendEmail } from './sendMail';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Modal, Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Container, Grid, IconButton, FormControlLabel, Slider, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, List, Card, CardContent, CircularProgress, Tooltip, InputAdornment, Accordion, AccordionSummary, AccordionDetails, Fab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';  // Pour le formatage localisé
import 'dayjs/locale/fr';  // Importez la localisation française
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import { Save as SaveIcon, Print as PrintIcon, Close as CloseIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';
import JsBarcode from 'jsbarcode';
import { useRef } from 'react';
import { useAlert } from './AlertContext';
import 'react-swipeable-list/dist/styles.css';
import ExportDemandes from './export';
import { fetchDemandes } from './models/getDemandes';
import { CremaCard } from './views/cremaCard';
import { DemandeLecture } from './views/viewDemande';
import { exportPDF } from './controllers/exportPDF';
import { print } from './controllers/print';
import { generateEmailContent } from './views/emailView';
import { onAuthStateChanged } from 'firebase/auth';
import AddIcon from '@mui/icons-material/Add';
dayjs.locale('fr');
dayjs.extend(localizedFormat);

export const GetDemandes = ({ userRole, clinique = "", onUpdateDemandes, userID }) => {
    const [demandes, setDemandes] = useState([]);
    const [viewDemande, setViewDemande] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [vetoUsers, setVetoUsers] = useState([]);
    const [openExport, setOpenExport] = useState(false);

    const fetchVetoUsers = async () => {
        if (userRole === 'admin') {
            try {
                const vetoCollectionRef = collection(db, "veto");
                const q = query(vetoCollectionRef);
                const vetoSnapshot = await getDocs(q);
                const vetoData = vetoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setVetoUsers(vetoData);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs veto:", error);
            }
        }
    };

    useEffect(() => {
        if (clinique) {
            const unsubscribe = fetchDemandes(userRole, (demandesData) => {
                setDemandes(demandesData);
                setLoading(false);
            }, clinique, userID);
            fetchVetoUsers();
            return () => unsubscribe && unsubscribe();
        }
    }, [userRole, clinique]);

    const handleRowClick = (demande) => {
        setViewDemande(demande);
        setOpenAddModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setViewDemande(null);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewDemande(null);
    };

    const handleUpdateDemandes = async () => {
        const updatedDemandes = await fetchDemandes();
        setDemandes(updatedDemandes);
    };

    const renderDemandeCard = (demande) => {
        return (
            <CremaCard
                key={demande.id}
                demande={demande}
                handleEdit={() => handleRowClick(demande)}
            />
        );
    };
    return (

        <Container maxWidth="lg" >
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Demandes</Typography>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                userRole === 'admin' ? (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <Button
                                color="primary"
                                onClick={() => setOpenExport(true)}
                                startIcon={<PdfIcon />}
                            >
                                Exporter les Demandes
                            </Button>
                        </Box>
                        <ExportDemandes open={openExport} onClose={() => setOpenExport(false)} />
                        <Box sx={{ marginTop: 4, paddingBottom: 5 }}>
                            {vetoUsers.filter(veto => demandes.some(demande => (demande.userID || demande.UID) === veto.id)).map((veto) => (
                                <Accordion key={veto.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{veto.clinique} - {veto.ville}</Typography>
                                        {demandes.some(demande => (demande.userID || demande.UID) === veto.id && demande.statut === 'Nouvelle demande') && (
                                            <span style={{
                                                display: 'inline-block',
                                                width: '8px',
                                                height: '8px',
                                                backgroundColor: '#FB6D48',
                                                borderRadius: '50%',
                                                marginLeft: '8px'
                                            }}></span>
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* Grouper les demandes par mois */}
                                        {Object.entries(
                                            demandes
                                                .filter(demande => (demande.userID || demande.UID) === veto.id)
                                                .reduce((acc, demande) => {
                                                    const date = new Date(demande.created);
                                                    const monthYear = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
                                                    if (!acc[monthYear]) {
                                                        acc[monthYear] = [];
                                                    }
                                                    acc[monthYear].push(demande);
                                                    return acc;
                                                }, {})
                                        )
                                            .sort((a, b) => {
                                                const dateA = new Date(a[1][0].created);
                                                const dateB = new Date(b[1][0].created);
                                                return dateB - dateA;
                                            })
                                            .map(([monthYear, monthDemandes]) => {
                                                // Vérifier si c'est le mois en cours
                                                const currentMonth = new Date().toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
                                                const isCurrentMonth = monthYear === currentMonth;

                                                return (
                                                    <Accordion
                                                        key={monthYear}
                                                        sx={{ backgroundColor: '#f8f8f8' }}
                                                        defaultExpanded={isCurrentMonth}
                                                    >
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <Typography sx={{ textTransform: 'capitalize' }}>
                                                                    {monthYear}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ marginLeft: 2, color: 'text.secondary' }}>
                                                                    ({monthDemandes.length} demandes)
                                                                </Typography>
                                                                {monthDemandes.some(demande => demande.statut === 'Nouvelle demande') && (
                                                                    <span style={{
                                                                        display: 'inline-block',
                                                                        width: '8px',
                                                                        height: '8px',
                                                                        backgroundColor: '#FB6D48',
                                                                        borderRadius: '50%',
                                                                        marginLeft: '8px'
                                                                    }}></span>
                                                                )}
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={3} justifyContent="center">
                                                                {monthDemandes
                                                                    .sort((a, b) => {
                                                                        if (a.statut === 'Nouvelle demande' && b.statut !== 'Nouvelle demande') {
                                                                            return -1;
                                                                        }
                                                                        if (a.statut !== 'Nouvelle demande' && b.statut === 'Nouvelle demande') {
                                                                            return 1;
                                                                        }
                                                                        return new Date(b.created) - new Date(a.created);
                                                                    })
                                                                    .map((demande) => renderDemandeCard(demande))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </>
                ) : (
                    <Box>
                        {demandes.some(demande => demande.statut !== 'Crémation effectuée') && (
                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Demandes en cours</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3} justifyContent="center" sx={{}}>
                                        {demandes
                                            .filter(demande => demande.statut !== 'Crémation effectuée')
                                            .map(demande => renderDemandeCard(demande))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {demandes.some(demande => demande.statut === 'Crémation effectuée') && (
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Crémations effectuées</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10 }}>
                                        {demandes
                                            .filter(demande => demande.statut === 'Crémation effectuée')
                                            .map(demande => renderDemandeCard(demande))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                )
            )}
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpenAddModal(true)} // Assurez-vous que cette fonction ouvre le modal pour "Nouvelle demande"
                sx={{
                    position: 'fixed',
                    bottom: 80,
                    right: 30,
                    backgroundColor: "#1976D2"
                }}
            >
                <AddIcon />
            </Fab>
            <AddDemandes demandeProp={viewDemande} isEditMode={false} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} clinique={clinique} userID={userID} />
        </Container>
    );
};



export const AddDemandes = ({ demandeProp, isEditMode: initialEditMode, userRole, onClose, open, userEmail, clinique = "", userID = "" }) => {
    userRole.userRole && (userRole = userRole.userRole);
    const [isEditMode, setIsEditMode] = useState(initialEditMode || false);
    const [user, setUser] = useState(null);
    const [demande, setDemande] = useState({
        animalName: '',
        animalType: '',
        animalWeight: '',
        // deathDateTime: null,
        animalRace: '',
        cremationType: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerAddress: '',
        ownerPostalCode: '',
        ownerCity: '',
        ownerPhone: '',
        statut: 'Nouvelle demande',
        ramassageDate: null,
        ramassageTime: '',
        urne: false,
        urneType: '',
        pierreEternelleType: '',
        pierreEternelle: false,
        pierreEternelleValue: 0,
        barcode: '',
        id: null,
        created: new Date().toISOString(),
        deleted: false,
        UID: auth.currentUser ? auth.currentUser.uid : null,
        clinique: user ? user.clinique : "",
        vetoEmail: user ? user.email : "",
        userID: userID ? userID : ""
    });
    const [hidePrint, setHidePrint] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const barcodeRef = useRef(null);
    const [showBarcodeInput, setShowBarcodeInput] = useState(false);
    const [showBarcodeImage, setShowBarcodeImage] = useState(false);
    const [showBarcodeScanner, setShowBarcodeScanner] = useState(false); // Optionnel
    const location = useLocation();
    const [loadingPDF, setLoadingPDF] = useState(false);
    const { id } = useParams();
    const { showAlert } = useAlert(); // Utiliser useAlert


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user && clinique && userID) {
                // console.log(userID);
                user.getIdTokenResult().then(async token => {
                    const userDocRef = doc(db, "veto", userID);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        // Traitez les données de l'utilisateur ici
                        setUser({ ...userData, id: userDoc.id });
                        setDemande(prevDemande => ({
                            ...prevDemande,
                            clinique: userData.clinique,
                            vetoEmail: userData.email,
                            userID: userDoc.id
                        }));
                    } else {
                        console.log("Aucun document trouvé pour l'ID:", userID);
                    }
                });
            }
        });

        return () => unsubscribe();
    }, [clinique, userID]);

    useEffect(() => {
        if (open && demandeProp) {
            setDemande({
                ...demandeProp,
                id: demandeProp.id
            });
            if (demandeProp.barcode) {
                setShowBarcodeImage(true); // Set showBarcodeImage to true if barcode exists
            }
        }
        setIsEditMode(demandeProp?.animalName === '' || demandeProp?.animalName === undefined);
        // else {
        //     setDemande({
        //         ...demande,
        //         animalName: '',
        //         animalType: '',
        //         animalWeight: '',
        //         // deathDateTime: null,
        //         animalRace: '',
        //         cremationType: '',
        //         ownerFirstName: '',
        //         ownerLastName: '',
        //         ownerAddress: '',
        //         ownerPostalCode: '',
        //         ownerCity: '',
        //         ownerPhone: '',
        //         statut: 'Nouvelle demande',
        //         ramassageDate: null,
        //         ramassageTime: '',
        //         id: null,
        //         UID: auth.currentUser ? auth.currentUser.uid : null,
        //         urne: false,
        //         barcode: '',
        //         urneType: '',
        //         pierreEternelleType: '',
        //         pierreEternelle: false,
        //         pierreEternelleValue: 0,
        //         created: new Date().toISOString(),
        //         deleted: false,
        //         clinique: '',
        //         vetoEmail: ''
        //     });
        // }
    }, [open, demandeProp]);

    useEffect(() => {
        // Si une nouvelle demande est ouverte, forcer le mode édition
        if (!demandeProp || demandeProp.animalName === '') {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [demandeProp]);

    useEffect(() => {
        if (showBarcodeImage && barcodeRef.current && demande.barcode) {
            JsBarcode(barcodeRef.current, demande.barcode, { format: "CODE128", width: 2, height: 40 });
        }
    }, [showBarcodeImage, barcodeRef.current, demande.barcode]);

    useEffect(() => {
        // Si une nouvelle demande est ouverte, forcer le mode édition
        if (!demandeProp) {
            setIsEditMode(true);
        } else {
            setIsEditMode(initialEditMode || false);
        }
    }, [demandeProp, initialEditMode]);

    // useEffect(() => {
    //     const fetchVetoInfo = async () => {
    //         if (demande.UID) {
    //             try {
    //                 const vetoDocRef = doc(db, "veto", demande.UID);
    //                 console.log(demande.UID);
    //                 // const q = query(vetoDocRef, where("UID", "==", demande.UID));
    //                 const vetoDoc = await getDoc(vetoDocRef);
    //                 if (vetoDoc.exists()) {
    //                     const vetoData = vetoDoc.data();
    //                     // console.log(vetoData);
    //                     setDemande(prev => ({ ...prev, clinique: vetoData.clinique, vetoEmail: vetoData.email }));
    //                 } else {
    //                     console.error("Aucun document trouvé pour l'UID du véto:", demande.UID);
    //                 }
    //             } catch (error) {
    //                 console.error("Erreur lors de la récupération des informations du véto:", error);
    //             }
    //         }
    //     };

    //     fetchVetoInfo();
    // }, [demandeProp]);
    const handleChange = async (event) => {
        const { name, value } = event.target;
        setDemande(prev => ({ ...prev, [name]: value }));

        if (name === 'cremationType' && value !== 'Crémation collective') {
            setDemande(prev => ({ ...prev, urne: true, pierreEternelle: true }));
        }
        if (name === 'cremationType' && value === 'Crémation collective') {
            setDemande(prev => ({ ...prev, urne: false, pierreEternelle: false }));
        }

        if (name === 'statut' && value === 'Crémation effectuée') {
            let emailContent = generateEmailContent(demande);
            const currentDate = new Date().toISOString();
            setDemande(prev => ({ ...prev, cremaDate: currentDate }));
            const send = await handleSendEmail(demande.userEmail, 'accueil@crematorium-animalierdepicardie.fr', 'Crémation effectuée', emailContent);
            if (send.message === "Email sent successfully via Brevo") {
                showAlert("Email envoyé avec succès !", 'success');
            } else {
                showAlert("Erreur lors de l'envoi de l'email !", 'error');
            }
        }
    };

    const handleChangeSwitch = (event) => {
        const { name, checked } = event.target;
        setDemande(prev => ({ ...prev, [name]: checked }));
    };

    const handleDateChangeRammassage = (newDate) => {
        setDemande(prev => ({ ...prev, ramassageDate: newDate ? newDate.toISOString() : null }));
    };

    const handleDateChangeCrema = (newDate) => {
        setDemande(prev => ({
            ...prev,
            cremaDate: newDate && !isNaN(newDate) ? newDate.toISOString() : null,
            statut: newDate ? 'Crémation effectuée' : prev.statut
        }));
    };

    const generateBarcode = () => {
        const newBarcode = `CREMA-${Date.now()}`;
        setDemande(prev => ({ ...prev, barcode: newBarcode }));
        setShowBarcodeInput(false);
        setShowBarcodeImage(true);
        setShowBarcodeScanner(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            let emailContent = generateEmailContent(demande);
            // demande.clinique = user
            if (demande.id) {
                // Mise à jour d'une demande existante
                await updateDoc(doc(db, "demandes", demande.id), demande);
                // Mettre à jour la liste des demandes dans le composant GetDemandes
                // if (typeof onUpdateDemandes === 'function') {
                //     onUpdateDemandes();
                // }
                handleClose();
                showAlert("Demande mise à jour avec succès !", 'success');
                const send = await handleSendEmail(userEmail, "accueil@crematorium-animalierdepicardie.fr", 'Demande de Crémation modifiée', emailContent);
                if (send.message === "Email sent successfully via Brevo") {
                    showAlert("Email envoyé avec succès !", 'success');
                }
                else {
                    showAlert("Erreur lors de l'envoi de l'email !", 'error');
                }
            } else {
                demande.clinique = user.clinique;
                demande.vetoEmail = user.email;
                // Création d'une nouvelle demande
                const { id, ...data } = demande;
                const docRef = await addDoc(collection(db, "demandes"), data);
                setIsEditMode(false);
                showAlert("Nouvelle demande créée avec succès !", 'success');
                const send = await handleSendEmail(userEmail, "accueil@crematorium-animalierdepicardie.fr", 'Demande de Crémation envoyée', emailContent);
                if (send.message === "Email sent successfully via Brevo") {
                    showAlert("Email envoyé avec succès !", 'success');
                }
                else {
                    showAlert("Erreur lors de l'envoi de l'email !", 'error');
                }
                setDemande({ ...data, id: docRef.id });
            }
        } catch (error) {
            console.error("Erreur lors de la soumission de la demande :", error);
            setSnackbarMessage("Erreur lors de la soumission de la demande.");
            setAlertSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleBarcodeInputChange = (event) => {
        const code = event.target.value;
        setDemande(prev => ({ ...prev, barcode: code }));
        if (code) {
            setShowBarcodeImage(true);
        } else {
            setShowBarcodeImage(false);
        }
    };

    const handleClose = () => {
        // setOpen(false);
        // if (demande.id) {
        //   setIsEditMode(true);
        setDemande({
            animalName: '',
            animalType: '',
            animalWeight: '',
            // deathDateTime: null,
            animalRace: '',
            cremationType: '',
            ownerFirstName: '',
            ownerLastName: '',
            ownerAddress: '',
            ownerPostalCode: '',
            ownerCity: '',
            ownerPhone: '',
            statut: 'Nouvelle demande',
            ramassageDate: null,
            ramassageTime: '',
            urne: false,
            urneType: '',
            pierreEternelleType: '',
            pierreEternelle: false,
            pierreEternelleValue: 0,
            barcode: '',
            id: null,
            created: new Date().toISOString(),
            deleted: false,
            UID: auth.currentUser ? auth.currentUser.uid : null,
            clinique: '',
            vetoEmail: ''
        });
        // }
        if (onClose) onClose();
    };

    const handleDeleteDemande = async () => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?")) {
            const demandeRef = doc(db, "demandes", demande.id);
            try {
                await updateDoc(demandeRef, {
                    deleted: true
                });
                handleClose();
                showAlert("Demande supprimée avec succès !", 'success');
            } catch (error) {
                console.error("Erreur lors de la suppression de la demande:", error);
                showAlert("Erreur lors de la suppression.", 'error');
            }
        }
    };

    const handlePrint = () => {
        const elementId = 'export-pdf';
        print(elementId);
    };

    const handlePdf = async () => {
        if (isEditMode) {
            setIsEditMode(false);
            setLoadingPDF(true);
            await new Promise(resolve => setTimeout(resolve, 2000));
        }
        else {
            setLoadingPDF(true);
        }
        try {
            const elementId = 'export-pdf';
            const filename = `Demande_Cremation_${new Date().toLocaleDateString('fr-FR')}.pdf`;

            const success = await exportPDF(elementId, filename, true, demande.cremationType);

            await new Promise(resolve => setTimeout(resolve, 1000));

            if (success) {
                showAlert("PDF généré avec succès !", 'success');
            } else {
                showAlert("Erreur lors de la génération du PDF !", 'error');
            }
        } finally {
            setLoadingPDF(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {demande.id ? "Modifier la demande" : "Nouvelle demande"}
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative', top: 8, right: 8 }}>
                        {(demande.id || !isEditMode) && (
                            <>
                                <div hidden={isEditMode || demande.statut !== "Nouvelle demande" && userRole !== "admin"}>
                                    <IconButton onClick={() => setIsEditMode(true)} color="primary" sx={{ color: 'black' }}>
                                        <EditIcon sx={{ fontSize: "2rem" }} />
                                    </IconButton>
                                </div>
                                <IconButton onClick={handlePdf} color="primary" sx={{ color: 'black' }}>
                                    {loadingPDF ? (
                                        <CircularProgress size={24} sx={{ color: 'black' }} />
                                    ) : (
                                        <PdfIcon sx={{ fontSize: "2rem" }} />
                                    )}
                                </IconButton>
                                {/* <IconButton onClick={handlePrint} color="primary" sx={{ color: 'black' }}>
                                    <PrintIcon sx={{ fontSize: "2rem" }} />
                                </IconButton> */}
                                <div hidden={userRole !== "admin"}>
                                    <IconButton onClick={handleDeleteDemande} color="primary" sx={{ color: 'black' }}>
                                        <DeleteIcon sx={{ fontSize: "2rem" }} />
                                    </IconButton>
                                </div>
                            </>
                        )}
                        <IconButton onClick={handleClose} color="inherit" sx={{ fontSize: "2rem", color: 'black' }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Container id="container" sx={{ width: "100%", }}>
                    <Box component="form" onSubmit={handleFormSubmit} sx={{ '& .MuiTextField-root': { m: 1 }, '& .MuiButton-root': { m: 1 }, paddingLeft: 1, paddingRight: 1 }}>
                        <Grid container spacing={2} direction="column" id="export-pdf">

                            {showBarcodeImage && (
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <svg ref={barcodeRef} style={{ width: '150px', height: '20px' }}></svg>
                                </Grid>
                            )}

                            {/* )} */}

                            {isEditMode ? (
                                <>
                                    <Grid item xs={12}>
                                        <Box sx={{ border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', p: 2, mb: 2, position: 'relative', marginTop: 2 }}>
                                            <TextField
                                                label="Code-barres"
                                                variant="outlined"
                                                name="barcode"
                                                value={demande.barcode}
                                                onChange={handleBarcodeInputChange}
                                                disabled={!isEditMode}
                                                fullWidth
                                                // autoFocus
                                                required
                                                sx={{ fontSize: 16 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Le code-barres peut être scanné avec une douchette ou saisi manuellement. Il s'affichera automatiquement lors de la saisie">
                                                                <IconButton>
                                                                    <InfoOutlinedIcon sx={{ color: "#7CB1E2" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Box sx={{ border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', p: 2, mb: 2, position: 'relative', marginTop: 2 }}>
                                        <Typography variant="caption" sx={{ position: 'absolute', top: '-10px', left: '10px', backgroundColor: '#f9f9f9', px: 1, fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                                            Informations de l'animal
                                        </Typography>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="animalName"
                                                label="Nom de l'animal"
                                                value={demande.animalName}
                                                onChange={handleChange}
                                                fullWidth
                                                required={demande.animalName !== "Inconnu"}
                                                disabled={demande.animalName === "Inconnu"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={demande.animalName === "Inconnu"}
                                                        onChange={(e) => {
                                                            setDemande(prev => ({
                                                                ...prev,
                                                                animalName: e.target.checked ? "Inconnu" : ""
                                                            }));
                                                        }}
                                                        name="animalNameUnknown"
                                                    />
                                                }
                                                label="Nom de l'animal inconnu"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth sx={{ m: 1 }}>
                                                <InputLabel>Type de l'animal</InputLabel>
                                                <Select
                                                    name="animalType"
                                                    value={demande.animalType}
                                                    onChange={handleChange}
                                                    required
                                                    label="Type de l'animal"
                                                >
                                                    <MenuItem value="Chien">Chien</MenuItem>
                                                    <MenuItem value="Chat">Chat</MenuItem>
                                                    <MenuItem value="NAC">NAC</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="animalRace"
                                                label="Race de l'animal"
                                                value={demande.animalRace}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="animalWeight"
                                                label="Poids de l'animal"
                                                value={demande.animalWeight}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box sx={{ border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', p: 2, mb: 2, position: 'relative', marginTop: 2 }}>
                                        <Typography variant="caption" sx={{ position: 'absolute', top: '-10px', left: '10px', backgroundColor: '#f9f9f9', px: 1, fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                                            Choix de la crémation et accessoires
                                        </Typography>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth sx={{ m: 1 }}>
                                                <InputLabel>
                                                    Type de crémation
                                                    {/* <Tooltip title="Sélectionnez le type de crémation souhaité">
                                                        <IconButton size="small" sx={{ marginLeft: 1 }}>
                                                            <InfoOutlinedIcon sx={{ color: "#7CB1E2" }} />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </InputLabel>
                                                <Select
                                                    name="cremationType"
                                                    value={demande.cremationType}
                                                    onChange={handleChange}
                                                    required
                                                    label="Type de crémation"
                                                >
                                                    <MenuItem value="Crémation collective">Crémation collective</MenuItem>
                                                    <MenuItem value="Crémation individuelle non accompagnée">Crémation individuelle non accompagnée</MenuItem>
                                                    <MenuItem value="Crémation individuelle accompagnée">Crémation individuelle accompagnée</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Switch name='urne' checked={demande.urne} onChange={handleChangeSwitch} disabled={demande.cremationType !== 'Crémation collective' || demande.cremationType === 'Crémation collective'} />}
                                                label="Urne"
                                            />
                                        </Grid>
                                        {demande.urne && (
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ m: 1 }}>
                                                    <InputLabel>Récupération</InputLabel>
                                                    <Select
                                                        name="urneType"
                                                        value={demande.urneType}
                                                        onChange={handleChange}
                                                        required={demande.urne}
                                                        label="Récupération"
                                                    >
                                                        <MenuItem value="à récupérer au crématorium par le propriétaire">à récupérer au crématorium par le propriétaire</MenuItem>
                                                        <MenuItem value="à récupérer à la clinique vétérinaire">à récupérer à la clinique vétérinaire</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {/* <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Switch name='pierreEternelle' checked={demande.pierreEternelle} onChange={handleChangeSwitch} disabled={demande.cremationType !== 'Crémation collective' || demande.cremationType === 'Crémation collective'} />}
                                                label="Pierre éternelle"
                                            />
                                        </Grid> */}
                                        {demande.pierreEternelle && (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="pierreEternelleValue"
                                                        label="Nombre de pierres éternelles"
                                                        type="number"
                                                        value={demande.pierreEternelleValue || ''}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 10
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Si vous ne souhaitez pas de pierre éternelle, noter 0">
                                                                        <IconButton>
                                                                            <InfoOutlinedIcon sx={{ color: "#7CB1E2" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        required={demande.pierreEternelle}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    <FormControl fullWidth sx={{ m: 1 }}>
                                                        <InputLabel>Récupération</InputLabel>
                                                        <Select
                                                            name="pierreEternelleType"
                                                            value={demande.pierreEternelleType}
                                                            onChange={handleChange}
                                                            required={demande.pierreEternelle}
                                                            label="Récupération"
                                                        >
                                                            <MenuItem value="à récupérer au crématorium par le propriétaire">à récupérer au crématorium par le propriétaire</MenuItem>
                                                            <MenuItem value="à récupérer à la clinique vétérinaire">à récupérer à la clinique vétérinaire</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> */}
                                            </>
                                        )}
                                    </Box>
                                    {demande.cremationType && demande.cremationType !== "Crémation collective" && (
                                        <Box sx={{ border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', p: 2, mb: 2, position: 'relative', marginTop: 2 }}>
                                            <Typography variant="caption" sx={{ position: 'absolute', top: '-10px', left: '10px', backgroundColor: '#f9f9f9', px: 1, fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                                                Informations du propriétaire
                                            </Typography>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerFirstName"
                                                    label="Prénom du propriétaire"
                                                    value={demande.ownerFirstName}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerLastName"
                                                    label="Nom du propriétaire"
                                                    value={demande.ownerLastName}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerAddress"
                                                    label="Adresse du propriétaire"
                                                    value={demande.ownerAddress}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerPostalCode"
                                                    label="Code postal du propriétaire"
                                                    value={demande.ownerPostalCode}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerCity"
                                                    label="Ville du propriétaire"
                                                    value={demande.ownerCity}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="ownerPhone"
                                                    label="Téléphone du propriétaire"
                                                    value={demande.ownerPhone}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        </Box>
                                    )}
                                    {demande.id !== null && (
                                        <Box sx={{ border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', p: 2, mb: 2, position: 'relative', marginTop: 2 }}>
                                            <Typography variant="caption" sx={{ position: 'absolute', top: '-10px', left: '10px', backgroundColor: '#f9f9f9', px: 1, fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                                                Informations sur le ramassage et la crémation
                                            </Typography>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ m: 1 }}>
                                                    <InputLabel>Statut</InputLabel>
                                                    <Select
                                                        name="statut"
                                                        value={demande.statut}
                                                        onChange={handleChange}
                                                        disabled={!isEditMode || userRole !== 'admin'}
                                                        label="Statut"
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        <MenuItem value="Nouvelle demande">Nouvelle demande</MenuItem>
                                                        <MenuItem value="Ramassage programmé">Ramassage programmé</MenuItem>
                                                        <MenuItem value="Animal arrivé au crématorium">Animal arrivé au crématorium</MenuItem>
                                                        <MenuItem value="Crémation effectuée">Crémation effectuée</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                                                        <DatePicker
                                                            label="Date de ramassage"
                                                            InputLabelProps={{ shrink: true }}
                                                            name="ramassageDate"
                                                            value={demande.ramassageDate ? dayjs(new Date(demande.ramassageDate)) : null}
                                                            onChange={handleDateChangeRammassage}
                                                            disabled={!isEditMode || userRole !== 'admin'}
                                                            renderInput={(params) => (
                                                                <TextField {...params} fullWidth />
                                                            )}
                                                            format="DD/MM/YYYY"
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ m: 1 }}>
                                                    <InputLabel>Heure de ramassage</InputLabel>
                                                    <Select
                                                        name="ramassageTime"
                                                        value={demande.ramassageTime}
                                                        onChange={handleChange}
                                                        disabled={!isEditMode || userRole !== 'admin'}
                                                        label="Heure de ramassage"
                                                    >
                                                        <MenuItem value="Matin">Matin</MenuItem>
                                                        <MenuItem value="Après-midi">Après-midi</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                                                        <DatePicker
                                                            label="Date de crémation"
                                                            InputLabelProps={{ shrink: true }}
                                                            name="cremaDate"
                                                            value={demande.cremaDate ? dayjs(new Date(demande.cremaDate)) : null}
                                                            onChange={handleDateChangeCrema}
                                                            disabled={!isEditMode || userRole !== 'admin'}
                                                            renderInput={(params) => (
                                                                <TextField {...params} fullWidth />
                                                            )}
                                                            format="DD/MM/YYYY"
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </Box>
                                    )}
                                    <Grid item xs={12}>
                                        {isEditMode && (
                                            <FormControl fullWidth sx={{ m: 1 }}>
                                                <Button
                                                    type='submit'
                                                    variant="contained"
                                                    color="success"
                                                    startIcon={<SaveIcon sx={{ fontSize: "2rem" }} />}
                                                    sx={{ fontSize: "1rem", padding: "10px 20px" }}
                                                >
                                                    Enregistrer
                                                </Button>
                                            </FormControl>
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <DemandeLecture demande={demande} />
                                // <>
                                //     {renderField("Nom de l'animal", demande.animalName)}
                                //     {renderField("Type de l'animal", demande.animalType)}
                                //     {renderField("Race de l'animal", demande.animalRace)}
                                //     {renderField("Type de crémation", demande.cremationType)}
                                //     {renderField("Prénom du propriétaire", demande.ownerFirstName)}
                                //     {renderField("Nom du propriétaire", demande.ownerLastName)}
                                //     {renderField("Adresse du propriétaire", demande.ownerAddress)}
                                //     {renderField("Code postal du propriétaire", demande.ownerPostalCode)}
                                //     {renderField("Ville du propriétaire", demande.ownerCity)}
                                //     {renderField("Téléphone du propriétaire", demande.ownerPhone)}
                                //     {renderField("Urne", demande.urne ? 'Oui' : 'Non')}
                                //     {demande.urne && renderField("Type d'urne", demande.urneType)}
                                //     {renderField("Pierre éternelle", demande.pierreEternelle ? 'Oui' : 'Non')}
                                //     {demande.pierreEternelle && renderField("Valeur de la pierre éternelle", demande.pierreEternelleValue)}
                                //     {demande.pierreEternelle && renderField("Type de pierre éternelle", demande.pierreEternelleType)}
                                //     {demande.id !== null && (
                                //         <>
                                //             {renderField("Statut", demande.statut)}
                                //             {renderField("Date de ramassage", demande.ramassageDate ? dayjs(demande.ramassageDate).format('DD/MM/YYYY') : '')}
                                //             {renderField("Heure de ramassage", demande.ramassageTime)}
                                //         </>
                                //     )}
                                //     {renderField("Date de création", new Date(demande.created).toLocaleDateString('fr-FR') + ' à ' + new Date(demande.created).toLocaleTimeString('fr-FR'))}


                                // </>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export const exportDemandes = (onOpen, onClose, demandes) => {

    const handlePdf = () => {
        console.log("PDF");
    }

    return (
        <Dialog open={onOpen} onClose={onClose}>
            <DialogTitle>
                Export des demandes
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    {/* <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton> */}
                    <IconButton onClick={handlePdf}>
                        <PdfIcon />
                    </IconButton>
                </Box>
                <Container id="pdf-content" sx={{ width: "100%" }}>
                    <Typography variant="h5" align="center" fontWeight={600} fontSize={"120%"} sx={{ marginBottom: 2 }}>
                        {/* {selectedRamassage && selectedRamassage.length > 0 && selectedRamassage[0].clinique + " - " + `${new Date(selectedRamassage[0].ramassageDate).toLocaleDateString()} ${selectedRamassage[0].ramassageTime}`} */}
                    </Typography>
                    <Grid container spacing={2}>
                        {demandes && demandes.map((demande, index) => (
                            <React.Fragment key={index}>
                                <Card variant="outlined" sx={{ marginBottom: 2, backgroundColor: 'lightblue', width: '100%', marginLeft: 1, marginRight: 1 }}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
                                            <Typography variant="h6" align="center" fontWeight={600} fontSize={"100%"} sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '70%'
                                            }}>
                                                {demande.animalName + ' - ' + demande.animalType}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                                            <Box sx={{ marginRight: 1, color: "black" }}><Person /></Box>
                                            <Typography variant="body1" align="center" color="#222831">
                                                {demande.ownerFirstName} {demande.ownerLastName}
                                            </Typography>
                                        </Box>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div dangerouslySetInnerHTML={{ __html: demande.barcodeSvg }} />
                                        </Grid>
                                    </CardContent>
                                </Card>
                                {(index + 1) % 4 === 0 && <div style={{ pageBreakAfter: 'always' }}></div>}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

