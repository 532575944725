import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Box, TextField, Button, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useAlert } from './AlertContext'; // Remplacez par la bibliothèque d'alertes que vous utilisez
import { auth, db } from './firebase';
import UrneCard from './views/urneCard';
import CloseIcon from '@mui/icons-material/Close';

export const GetUrnes = (userRole, onUpdateUrnes) => {
    userRole.userRole && (userRole = userRole.userRole);
    const [urnes, setUrnes] = useState([]);
    const [viewUrne, setViewUrne] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openPhotoModal, setOpenPhotoModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const { showAlert } = useAlert();

    const fetchUrnes = () => {
        setLoading(true);
        const urnesCollectionRef = collection(db, "urnes");

        const unsubscribe = onSnapshot(urnesCollectionRef, (snapshot) => {
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            data.sort((a, b) => {
                const titreA = a.titre || '';
                const titreB = b.titre || '';
                return titreA.localeCompare(titreB);
            });
            setUrnes(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des urnes:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchUrnes();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleRowClick = (urne) => {
        if (userRole === "admin") {
            setViewUrne(urne);
            setOpenAddModal(true);
        } else {
            setViewUrne(urne);
            setOpenPhotoModal(true);
        } 
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewUrne(null);
    };

    const handleDeleteUrne = async (urne) => {
        try {
            await deleteDoc(doc(db, "urnes", urne.id)); // Suppression réelle de la base de données
            showAlert("Urne supprimée avec succès !", 'success');
        } catch (error) {
            console.error("Erreur lors de la suppression de l'urne :", error);
            showAlert("Erreur lors de la suppression.", 'error');
        }
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Liste des urnes</Typography>
            {isMobile ? (
                <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10, overflow: 'auto' }}>
                    {urnes.map((urne) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={urne.id}>
                            <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom:0, border: 'none' }} onClick={() => handleRowClick(urne)} style={{ cursor: 'pointer' }}>
                                <CardContent style={{ padding: 0 }}>
                                    <UrneCard urne={urne} onClick={() => handleRowClick(urne)} onDelete={() => handleDeleteUrne(urne)} userRole={userRole} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {userRole === 'admin' && (
                       <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => setOpenAddModal(true)} // Assurez-vous que cette fonction ouvre le modal pour "Nouvelle demande"
                            sx={{
                                position: 'fixed',
                                bottom: 80,
                                right: 30,
                                backgroundColor: "#1976D2"
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    )}
                    <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10, overflow: 'auto' }}>
                        {urnes.map((urne) => (
                            <Grid item xs={12} sm={12} md={6} lg={6} key={urne.id}>
                                <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom: 1.5 }} onClick={() => handleRowClick(urne)} style={{ cursor: 'pointer' }}>
                                    <CardContent style={{ padding: 0 }}>
                                        <UrneCard urne={urne} onClick={() => handleRowClick(urne)} onDelete={() => handleDeleteUrne(urne)} userRole={userRole} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}

            {/* {userRole === 'admin' && (
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpenAddModal(true)}
                    sx={{
                        position: 'fixed',
                        bottom: 80,
                        right: 30,
                        backgroundColor: "#1976D2"
                    }}
                >
                    <AddIcon />
                </Fab>
            )} */}
            <AddUrnes urneProp={viewUrne} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateUrnes={onUpdateUrnes}  />

            <Dialog open={openPhotoModal} onClose={() => setOpenPhotoModal(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenPhotoModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={viewUrne?.image} alt={viewUrne?.titre} style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export const AddUrnes = ({ urneProp, userRole, onClose, open, onUpdateUrnes }) => {
    const { showAlert } = useAlert();
    const [urne, setUrne] = useState({
        titre: '',
        texte: '',
        image: '',
        id: null,
    });

    useEffect(() => {
        if (urneProp) {
            setUrne(urneProp);
        } else {
            setUrne({ titre: '', texte: '', image: '', id: null });
        }
    }, [urneProp]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUrne(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            // Vérifiez si un fichier est sélectionné
            const fileInput = document.querySelector('input[name="image"]');
            const file = fileInput.files[0];
    
            let fileURL = urne.image; // Conserver l'URL existante si aucun nouveau fichier n'est téléchargé
    
            if (file) {
                // Téléchargez le fichier dans Firebase Storage
                const storage = getStorage();
                const storageRef = ref(storage, `images/${file.name}`);
                await uploadBytes(storageRef, file);
    
                // Obtenez l'URL de téléchargement
                fileURL = await getDownloadURL(storageRef);
            }
    
            // Mettez à jour la demande avec l'URL du fichier
            const updatedUrne = { ...urne, image: fileURL };
    
            if (urne.id) {
                // Mise à jour d'une demande existante
                await updateDoc(doc(db, "urnes", urne.id), updatedUrne);
                showAlert("Urne mise à jour avec succès !", 'success');
            } else {
                // Création d'une nouvelle demande
                const { id, ...data } = updatedUrne;
                const docRef = await addDoc(collection(db, "urnes"), data);
                setUrne({ ...data, id: docRef.id });
                showAlert("Nouvelle urne créée avec succès !", 'success');
            }
    
            // onUpdateUrnes();
            onClose();
        } catch (error) {
            console.error("Erreur lors de la soumission de l'urne :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        }
    };

    return (
        <Dialog open={open} onClose={() => { onClose(); setUrne({ titre: '', texte: '', image: '' }); }} maxWidth="md" fullWidth>
            <DialogTitle>
                {urne.id ? "Modifier l'urne" : "Nouvelle urne"}
                <IconButton
                    aria-label="close"
                    onClick={() => { onClose(); setUrne({ titre: '', texte: '', image: '' }); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                            <Box component="form" onSubmit={handleFormSubmit}>
                                <TextField
                                    name="titre"
                                    label="Titre"
                                    value={urne.titre}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    name="texte"
                                    label="Texte"
                                    value={urne.texte}
                                    onChange={handleChange}
                                    fullWidth
                                    // required
                                    multiline
                                    rows={4}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                    fullWidth
                                    required={!urne.id}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Button type='submit' variant="contained" color="success" sx={{ marginTop: 2 }}>
                                    Enregistrer
                                </Button>
                            </Box>
                        </FormControl>
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
