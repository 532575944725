import React, { useState, useRef, useEffect } from 'react';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { db } from './firebase';
import { doc, getDoc, collection, onSnapshot, updateDoc, query, where } from 'firebase/firestore';
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Alert, DialogContentText, IconButton, Box, Grid, Card, CardContent, Container, CircularProgress } from '@mui/material';
import { Pets as PetsIcon, Person, Medication, } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { handleSendEmail } from './sendMail';
import ScannerIcon from '@mui/icons-material/Scanner';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import { useAlert } from './AlertContext';
import dayjs from 'dayjs';
import JsBarcode from 'jsbarcode';
import html2pdf from 'html2pdf.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer le style de Quill
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const Check = (userEmail) => {
  const [scannedCodes, setScannedCodes] = useState([]);
  const [scanMode, setScanMode] = useState(null); // 'camera' ou 'scanner'
  const [demandes, setDemandes] = useState([]);
  const [ramassages, setRamassages] = useState([]); // Nouveau hook pour les créneaux de ramassage
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRamassage, setSelectedRamassage] = useState(null);
  const [barcode, setBarcode] = useState('');
  const [error, setError] = useState('');
  const lastScannedCode = useRef('');
  const { showAlert } = useAlert(); // Utiliser useAlert
  const [openNotifyDialog, setOpenNotifyDialog] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [emailTo, setEmailTo] = useState('');
  const [openRecapDialog, setOpenRecapDialog] = useState(false);
  const barcodeRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [groupedByClinique, setGroupedByClinique] = useState({});

  useEffect(() => {
    const fetchUserDetails = async (uid) => {
      const userDoc = await getDoc(doc(db, 'veto', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          email: userData.email,
          clinique: userData.clinique
        };
      }
      return null;
    };

    const unsubscribe = onSnapshot(
      query(
        collection(db, 'demandes'),
        where('deleted', '!=', true) // Utiliser un seul filtre '!='
      ),
      async (snapshot) => {
        const filteredDemandes = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(demande => demande.statut !== 'Crémation effectuée'); // Filtrer côté client

        // Utiliser les demandes filtrées
        setDemandes(filteredDemandes);
      }
    );

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const groupByClinique = () => {
      return Object.entries(ramassages).reduce((acc, [key, demandes]) => {
        demandes.forEach(demande => {
          const clinique = demande.clinique;
          if (!acc[clinique]) {
            acc[clinique] = {};
          }
          if (!acc[clinique][key]) {
            acc[clinique][key] = [];
          }
          acc[clinique][key].push(demande);
        });
        return acc;
      }, {});
    };

    setGroupedByClinique(groupByClinique());
  }, [ramassages]);

  useEffect(() => {
    const newRamassages = demandes.reduce((acc, demande) => {
      const { ramassageDate, ramassageTime, clinique, animalName, animalType, cremationType, barcode } = demande;
      if (ramassageDate && ramassageTime) {
        const key = `${new Date(ramassageDate).toLocaleDateString()} ${ramassageTime} : ${clinique}`;
        if (!acc[key]) {
          acc[key] = [];
        }

        // Créer un SVG pour le code-barres
        const barcodeSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        JsBarcode(barcodeSvg, barcode, {
          format: "CODE128",
          lineColor: "#000",
          width: 2,
          height: 40,
          displayValue: true
        });

        // Convertir le SVG en une chaîne de caractères
        const serializer = new XMLSerializer();
        const barcodeSvgString = serializer.serializeToString(barcodeSvg);

        // Ajouter le SVG à la demande
        acc[key].push({ ...demande, barcodeSvg: barcodeSvgString });
      }
      return acc;
    }, {});

    setRamassages(newRamassages);
  }, [demandes]);

  const handleNotify = (demandes) => {
    const dateRamassage = demandes[0].ramassageDate ? dayjs(demandes[0].ramassageDate).format('DD/MM/YYYY') : '';
    const timeOfDay = demandes[0].ramassageTime && demandes[0].ramassageTime.includes('AM') ? 'matin' : 'après-midi';
    let content = `Bonjour,<br/>Nous prévoyons de venir chercher les animaux ci-dessous le ${dateRamassage} ${timeOfDay}:<br/><br/>`;

    demandes.forEach(demande => {
      content += `Nom de l'animal: <b>${demande.animalName}</b><br/>`;
      content += `Race de l'animal: <b>${demande.animalRace}</b><br/>`;
      content += `Type de crémation: <b>${demande.cremationType}</b><br/><br/>`;
    });
    content += `<br/><br/>Cordialement,<br/>L'équipe du Crematorium Animalier de Picardie<br/><br/><br/>`;

    setEmailContent(content);
    setOpenNotifyDialog(true);
    setEmailTo(demandes[0].userEmail);
  };

  // Fonction pour fermer la boîte de dialogue de notification
  const handleCloseNotifyDialog = () => {
    setOpenNotifyDialog(false);
  };

  // Fonction pour envoyer l'email après modification
  const handleSendNotificationEmail = async () => {
    setOpenNotifyDialog(false);
    console.log(userEmail);
    const send = await handleSendEmail(`${emailTo}`, `accueil@crematorium-animalierdepicardie.fr`, 'Prévision de ramassage', emailContent);
    console.log(send);
    if (send.message === "Email sent successfully via Brevo") {
      showAlert("Email envoyé avec succès !", 'success');
    } else {
      showAlert("Erreur lors de l'envoi de l'email !", 'error');
    }
  };

  const handleScan = (data) => {
    console.log(data, scannedCodes, lastScannedCode.current);
    // if (data && !scannedCodes.includes(data) && data !== lastScannedCode.current) {
    const demande = demandes.find(d => d.barcode === data);
    if (demande) {
      console.log(demande);
      setScannedCodes((prevCodes) => [...prevCodes, data]);
      lastScannedCode.current = data;
      setBarcode('');
      handleValidateBarcode();
      setError('');
    } else {
      setError('Code barre non trouvé dans les demandes.');
    }
    // }
  };

  const handlePdf = () => {
    // Sélectionnez l'élément que vous souhaitez convertir en PDF
    const element = document.getElementById('pdf-content');

    // Options pour html2pdf
    const options = {
      margin: 1,
      filename: `Ramassage_${selectedRamassage[0].clinique}_${new Date(selectedRamassage[0].ramassageDate).toLocaleDateString()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Génération du PDF
    html2pdf().from(element).set(options).save();
  };

  const handleBarcodeScan = (event) => {
    if (event.key === 'Enter') {
      handleScan(event.target.value);
      event.target.value = ''; // Clear the input field after scanning
    }
  };

  const handleOpenDialog = (ramassage) => {
    setSelectedRamassage(ramassage);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBarcode('');
    setError('');
  };

  const handleOpenRecapDialog = (ramassage) => {
    setSelectedRamassage(ramassage);
    setOpenRecapDialog(true);
  };

  const handleCloseRecapDialog = () => {
    setOpenRecapDialog(false);
  };

  const handleValidateBarcode = async () => {
    console.info(barcode, selectedRamassage);
    const demande = selectedRamassage.find(d => d.barcode === barcode);
    console.info(demande, selectedRamassage);
    if (demande) {
      // Mettre à jour le statut de la demande
      await updateDemande(demande.id, { statut: 'Ramassage effectué' });

      // Mettre à jour l'état local pour refléter le changement
      setDemandes((prevDemandes) =>
        prevDemandes.map((d) =>
          d.id === demande.id ? { ...d, statut: 'Animal arrivé au crématorium' } : d
        )
      );

      setSelectedRamassage((prevRamassage) =>
        prevRamassage.map((d) =>
          d.id === demande.id ? { ...d, statut: 'Animal arrivé au crématorium' } : d
        )
      );

      setScannedCodes((prevCodes) => [...prevCodes, barcode]);
      setBarcode('');
      setError('');
    } else {
      setError('Code barre non trouvé dans les demandes.');
    }
  };

  const updateDemande = async (id, updatedData) => {
    const demandeRef = doc(db, 'demandes', id);
    try {
      await updateDoc(demandeRef, updatedData);
      console.log('Demande mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la demande: ', error);
    }
  };

  const handleValidate = async () => {
    handleClickOpen();
    const demandesScannees = selectedRamassage.filter(d => scannedCodes.includes(d.barcode));
    let emailContent = '<h2>Les demandes suivantes ont été scannées :</h2><br>';
    demandesScannees.forEach(demande => {
      emailContent += `<p><strong>Nom de l'animal:</strong> ${demande.animalName}</p>`;
      emailContent += `<p><strong>Type de l'animal:</strong> ${demande.animalType}</p>`;
      emailContent += `<p><strong>Race de l'animal:</strong> ${demande.animalRace}</p>`;
      emailContent += `<p><strong>Type de crémation:</strong> ${demande.cremationType}</p>`;
      emailContent += `<p><strong>Prénom du propriétaire:</strong> ${demande.ownerFirstName}</p>`;
      emailContent += `<p><strong>Nom du propriétaire:</strong> ${demande.ownerLastName}</p>`;
      emailContent += `<p><strong>Adresse du propriétaire:</strong> ${demande.ownerAddress}</p>`;
      emailContent += `<p><strong>Code postal du propriétaire:</strong> ${demande.ownerPostalCode}</p>`;
      emailContent += `<p><strong>Ville du propriétaire:</strong> ${demande.ownerCity}</p>`;
      emailContent += `<p><strong>Téléphone du propriétaire:</strong> ${demande.ownerPhone}</p>`;
      emailContent += `<p><strong>Urne:</strong> ${demande.urne ? 'Oui' : 'Non'}</p>`;
      if (demande.urne) {
        emailContent += `<p><strong>Type d'urne:</strong> ${demande.urneType}</p>`;
      }
      emailContent += `<p><strong>Pierre éternelle:</strong> ${demande.pierreEternelle ? 'Oui' : 'Non'}</p>`;
      if (demande.pierreEternelle) {
        emailContent += `<p><strong>Valeur de la pierre éternelle:</strong> ${demande.pierreEternelleValue}</p>`;
        emailContent += `<p><strong>Type de pierre éternelle:</strong> ${demande.pierreEternelleType}</p>`;
      }
      if (demande.id !== null) {
        emailContent += `<p><strong>Statut:</strong> ${demande.statut}</p>`;
        emailContent += `<p><strong>Date de ramassage:</strong> ${demande.ramassageDate ? dayjs(demande.ramassageDate).format('DD/MM/YYYY') : ''}</p>`;
        emailContent += `<p><strong>Heure de ramassage:</strong> ${demande.ramassageTime}</p>`;
      }
    });
    emailContent += '<br>';
    emailContent += `<br/><br/>Cordialement,<br/>L'équipe du Crematorium Animalier de Picardie<br/><br/><br/>`;
    setOpenDialog(false);
    const send = await handleSendEmail(demandesScannees[0].userEmail, "accueil@crematorium-animalierdepicardie.fr", 'Ramassage effectué', emailContent);
    if (send.message === "Email sent successfully via Brevo") {
      showAlert("Email envoyé avec succès !", 'success');
    }
    else {
      showAlert("Erreur lors de l'envoi de l'email !", 'error');
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    // Logique pour envoyer le mail au cabinet vétérinaire
    handleValidate();
    setOpen(false);
  };

  const renderField = (label, value) => (
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      <span>{label}: </span><span style={{ fontWeight: 'bold' }}>{value}</span>
    </Typography>
  );

  return (
    <div>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Ramassage</Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Affichage des créneaux de ramassage avec accordéon */}
          {Object.entries(groupedByClinique).map(([clinique, dates]) => (
            <Accordion key={clinique}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{clinique}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(dates).map(([dateKey, demandes]) => (
                  <Accordion key={dateKey}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{dateKey}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Tooltip title="Prévenir">
                        <IconButton color="secondary" onClick={() => handleNotify(demandes)}>
                          <EmailIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Scanner">
                        <IconButton color="primary" onClick={() => handleOpenDialog(demandes)}>
                          <CameraAltIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Récapitulatif">
                        <IconButton color="success" onClick={() => handleOpenRecapDialog(demandes)}>
                          <ListAltIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {demandes.map((demande, index) => (
                          <React.Fragment key={index}>
                            <Card variant="outlined" sx={{ marginBottom: 2, backgroundColor: demande.statut === 'Ramassage effectué' ? 'lightgreen' : 'transparent', width: '100%', marginLeft: 1, marginRight: 1, position: 'relative' }}>
                              {demande.statut === 'Ramassage effectué' && (
                                <Box sx={{ position: 'absolute', top: 8, right: 8, color: 'green' }}>
                                  <CheckIcon />
                                </Box>
                              )}
                              <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
                                  <Typography variant="h6" align="center" fontWeight={600} fontSize={"100%"} sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '70%'
                                  }}>
                                    {demande.animalName + ' - ' + demande.animalType}
                                  </Typography>
                                </Box>
                                {demande.ownerFirstName && demande.ownerLastName && (
                                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                                    <Box sx={{ marginRight: 1, color: "black" }}><Person /></Box>
                                    <Box>
                                      <Typography variant="body1" align="center" color="#222831">
                                        {demande.ownerFirstName} {demande.ownerLastName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                                <Typography variant="body1" align="center" color="#222831" fontWeight={600}>
                                  {demande.cremationType}
                                </Typography>
                                {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                              <div dangerouslySetInnerHTML={{ __html: demande.barcodeSvg }} />
                            </Grid> */}
                              </CardContent>
                            </Card>
                            {(index + 1) % 4 === 0 && <div style={{ pageBreakAfter: 'always' }}></div>}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Scanner les demandes</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Code barre"
            type="text"
            fullWidth
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            onKeyDown={handleBarcodeScan}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {selectedRamassage && selectedRamassage.map((demande, index) => (
              <React.Fragment key={index}>
                <Card variant="outlined" sx={{ marginBottom: 2, backgroundColor: demande.statut === 'Ramassage effectué' ? 'lightgreen' : 'transparent', width: '100%', marginLeft: 1, marginRight: 1 }}>
                  {demande.statut === 'Ramassage effectué' && (
                    <Box sx={{ float: 'right', color: 'green' }}>
                      <CheckIcon />
                    </Box>
                  )}
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
                      <Typography variant="h6" align="center" fontWeight={600} fontSize={"100%"} sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%'
                      }}>
                        {demande.animalName + ' - ' + demande.animalType}
                      </Typography>
                    </Box>
                    {demande.ownerFirstName && demande.ownerLastName && (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                        <Box sx={{ marginRight: 1, color: "black" }}><Person /></Box>
                        <Typography variant="body1" align="center" color="#222831">
                          {demande.ownerFirstName} {demande.ownerLastName}
                        </Typography>
                      </Box>
                    )}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div dangerouslySetInnerHTML={{ __html: demande.barcodeSvg }} />
                    </Grid>
                  </CardContent>
                </Card>
                {(index + 1) % 4 === 0 && <div style={{ pageBreakAfter: 'always' }}></div>}
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleClickOpen} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir valider ? Cela va générer un mail au cabinet vétérinaire avec la liste des animaux scannés.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNotifyDialog} onClose={handleCloseNotifyDialog}>
        <DialogTitle>Prévision de ramassage</DialogTitle>
        <DialogContent>
          <ReactQuill
            value={emailContent}
            onChange={setEmailContent}
            modules={{
              toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]}
            style={{ height: '300px', marginBottom: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotifyDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSendNotificationEmail} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRecapDialog} onClose={handleCloseRecapDialog}>
        <DialogTitle>
          Récapitulatif du ramassage
          <IconButton
            aria-label="close"
            onClick={handleCloseRecapDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            {/* <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton> */}
            <IconButton onClick={handlePdf}>
              <PictureAsPdfIcon />
            </IconButton>
          </Box>
          <Container id="pdf-content" sx={{ width: "100%" }}>
            <Typography variant="h5" align="center" fontWeight={600} fontSize={"120%"} sx={{ marginBottom: 2 }}>
              {selectedRamassage && selectedRamassage.length > 0 && selectedRamassage[0].clinique + " - " + `${new Date(selectedRamassage[0].ramassageDate).toLocaleDateString()} ${selectedRamassage[0].ramassageTime}`}
            </Typography>
            <Grid container spacing={2}>
              {selectedRamassage && selectedRamassage.map((demande, index) => (
                <React.Fragment key={index}>
                  <Card variant="outlined" sx={{ marginBottom: 2, backgroundColor: 'lightblue', width: '100%', marginLeft: 1, marginRight: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
                        <Typography variant="h6" align="center" fontWeight={600} fontSize={"100%"} sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '70%'
                        }}>
                          {demande.animalName + ' - ' + demande.animalType}
                        </Typography>
                      </Box>
                      {demande.ownerFirstName && demande.ownerLastName && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1 }}>
                          <Box sx={{ marginRight: 1, color: "black" }}><Person /></Box>
                          <Typography variant="body1" align="center" color="#222831">
                            {demande.ownerFirstName} {demande.ownerLastName}
                          </Typography>
                        </Box>
                      )}
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div dangerouslySetInnerHTML={{ __html: demande.barcodeSvg }} />
                      </Grid>
                    </CardContent>
                  </Card>
                  {(index + 1) % 4 === 0 && <div style={{ pageBreakAfter: 'always' }}></div>}
                </React.Fragment>
              ))}
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>

    </div>
  );
};

export default Check;
